<template>
  <b-overlay :show="show" rounded="lg">
    <b-card>
      <b-form @submit.prevent>
        <b-row>
          <b-col>BURASI ARAMA ALANI</b-col>
          <b-col md="2">

          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
export default {
  name: "ProductBundleSearch",
  data: () => ({
    show: false
  }),
}
</script>

<style scoped>

</style>
