<template>
  <section>
    <ProductBundleSearch/>
  </section>
</template>

<script>
import ProductBundleSearch from "@/views/product/bundle/ProductBundleSearch";

export default {
  name: "ProductBundleHome",
  components: {ProductBundleSearch}
}
</script>

<style scoped>

</style>
